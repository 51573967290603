import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useQuery } from '@apollo/client';
import { Chip, CircularProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { useUserToken } from '../../hooks/useUserToken';
import { QUERY_GET_VCC_DETAILS } from './VCCModal.gql';
import {
  ChipErrorStyle,
  ChipSuccessStyle,
  ItemStyle,
  LoadingStyle,
  ModalBottomContentStyle,
  ModalHeaderStyle,
  ModalMiddleContentStyle,
  ModalStyle,
  ModalTopBodyStyle,
  ModalTopContentStyle,
  StyledH1,
  StyledH5,
  StyledH6
} from './VCCModal.styles';
import { colorBlue, colorBurgundy, colorGrey02, colorGrey03, colorRed, colorWhite } from '../../layout/css/colors';
import VirtualCard from './art/VirtualCard';
import { stripError } from '../../lib/utils';
import CopyToClipboardButton from '../copyToClipBoard/CopyToClipBoardButton';
import { getErrorDetails } from '../../lib/dictionary/errors';

const Item = (props) => {
  const { label, children } = props;
  return (
    <Box>
      <StyledH6 sx={ItemStyle} size="12px" color={colorGrey03}>
        {label}
      </StyledH6>
      <StyledH6 color={colorBurgundy} size="18px">
        {children}
      </StyledH6>
    </Box>
  );
};

const getVCCData = (data) => {
  const vcc = get(data, 'PartnerHubQueries.partnerGetVCCDetails', []);
  return vcc;
};

const VCCModal = (props) => {
  const { vccModalOpen: bookingNumber, handleSetState } = props;

  const handleClose = () => handleSetState({ vccModalOpen: false });
  const { token: userToken } = useUserToken();
  const { data, loading, error } = useQuery(QUERY_GET_VCC_DETAILS, {
    variables: { token: userToken.token, bookingNumber },
    fetchPolicy: 'cache-first'
  });
  useEffect(() => {
    let timer;
    if (bookingNumber) {
      timer = setTimeout(
        () => {
          handleClose();
        },
        15 * 60 * 1000 // 15 minute automatic closure
      );
    }
    return () => clearTimeout(timer);
  }, [bookingNumber, handleSetState]);

  const vcc = getVCCData(data);
  const { provider = '', currency = '' } = vcc;
  const filteredProvider = provider.replace(/([^\w ]|_)/g, ''); // Removes special characters
  const isCSI = filteredProvider.localeCompare('CSI', undefined, { sensitivity: 'base' }) === 0;
  const isCA = currency.localeCompare('CAD', undefined, { sensitivity: 'base' }) === 0;

  if (loading)
    return (
      <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <Box sx={ModalHeaderStyle}>
            <StyledH1 size="3ch" color={colorWhite}>
              Virtual Credit Card Authorization
            </StyledH1>
          </Box>
          <Box sx={LoadingStyle}>
            <CircularProgress />
          </Box>
        </Box>
      </Modal>
    );

  if (error) {
    const { errorTitle, errorMessage } = getErrorDetails(stripError(error));
    return (
      <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <ErrorIcon style={{ color: colorRed, marginBottom: '1rem' }} fontSize="large" />
          <Box sx={ModalHeaderStyle}>
            <StyledH1 size="3ch" color={colorWhite}>
              Virtual Credit Card Authorization
            </StyledH1>
          </Box>
          <Typography id="modal-modal-title" sx={{ m: 2 }} variant="h6" component="h2" fontWeight="bold">
            {errorTitle}
          </Typography>
          <Typography id="modal-modal-description" sx={{ m: 2 }} color={colorGrey03} fontWeight="strong">
            {errorMessage}
          </Typography>
        </Box>
      </Modal>
    );
  }
  return (
    <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={ModalStyle}>
        <Box sx={ModalHeaderStyle}>
          <StyledH1 size="3ch" color={colorWhite}>
            Virtual Credit Card Authorization
          </StyledH1>
        </Box>
        <Box sx={ModalTopBodyStyle}>
          <Box sx={ModalTopContentStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Booking Details
            </Typography>
            <Grid container spacing={2}>
              <Grid xs={10}>
                <span className="notranslate">
                  <Item label="Guest Names">{vcc.guestName}</Item>
                </span>
              </Grid>
              <Grid xs={10}>
                <Item label="Confirmation Number">{vcc.confirmationNumber}</Item>
              </Grid>
              <Grid xs={4}>
                <span className="notranslate">
                  <Item label="Customer/Company Name">{vcc.customerName}</Item>
                </span>
              </Grid>
              <Grid xs={3}>
                <Item label="Booking Number">{vcc.bookingNumber}</Item>
              </Grid>
              <Grid xs={4}>
                <Item label="Duration">{vcc.duration}</Item>
              </Grid>
            </Grid>
          </Box>
          <Divider variant="middle" sx={{ m: 2 }} />
          <Box sx={ModalTopContentStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Credit Card Information
            </Typography>
            <Grid container spacing={4}>
              <Grid>
                <VirtualCard vcc={vcc} isCSI={isCSI} isCA={isCA} />
              </Grid>
              <Grid xs={5}>
                <span className="notranslate">
                  <Item label="Card Holder Name">
                    {vcc.cardholderName}
                    <CopyToClipboardButton copiedText={vcc.cardholderName} />
                  </Item>
                </span>
                <Item label="Card Number">
                  {vcc.cardNumber}
                  <CopyToClipboardButton copiedText={vcc.cardNumber} />
                </Item>
                <Item label="Expiry">
                  {vcc.expiry}
                  <CopyToClipboardButton copiedText={vcc.expiry} />
                </Item>
                <Item label="CVV">
                  {vcc.cvv}
                  <CopyToClipboardButton copiedText={vcc.cvv} />
                </Item>
              </Grid>
            </Grid>
          </Box>
          {vcc.incidentals ? (
            <Chip
              color="success"
              icon={<CheckIcon />}
              sx={ChipSuccessStyle}
              label="This customer is authorized to use VCC to cover incidentals/deposit."
            />
          ) : (
            <Chip
              color="error"
              icon={<CloseIcon />}
              sx={ChipErrorStyle}
              label="This VCC is not authorized to cover incidentals."
            />
          )}
          <Divider variant="middle" sx={{ m: 2 }} />
          <Box sx={ModalMiddleContentStyle}>
            <StyledH1 color={colorRed} size="20px">
              If there is an issue with the LodgeLink form of payment please contact us, do not charge the guest.
              <br />
              <strong>support@lodgelink.com</strong> or <strong>1-844-590-5465</strong>
            </StyledH1>
          </Box>
          <Box sx={ModalBottomContentStyle}>
            <StyledH5 color={colorGrey02} size="16px">
              Pre-negotiated corporate rates and discounts apply. Please do not disclose our rates to your guests.
              <br />
              Please refer to your LodgeLink Contract or contact us at{' '}
              <span style={{ color: colorBlue }}>support@lodgelink.com</span> of you have any questions.
              <br />
              At the time of check out plese send all invoices to{' '}
              <span style={{ color: colorBlue }}>folios@lodgelink.com</span> and we will invoice the guest separately.
              <br />
              More information <span style={{ color: colorBlue }}>lodgelink.com/becoming-a-supplier</span>
            </StyledH5>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

VCCModal.propTypes = {
  vccModalOpen: PropTypes.string,
  handleSetState: PropTypes.func
};

VCCModal.defaultProps = {
  vccModalOpen: '',
  handleSetState: () => {}
};

Item.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.shape({}).isRequired
};

export default VCCModal;
