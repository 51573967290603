import gql from 'graphql-tag';

export const QUERY_CREW_MEMBER_REGISTRATION = gql`
  query QUERY_CREW_MEMBER_REGISTRATION(
    $propertyName: String
    $propertyId: String
    $startDate: String
    $token: String!
    $searchText: String
    $endDate: String
    $top: Int
    $skip: Int
  ) {
    getCrewRegistrationData(
      propertyName: $propertyName
      token: $token
      startDate: $startDate
      searchText: $searchText
      propertyId: $propertyId
      endDate: $endDate
      top: $top
      skip: $skip
    ) {
      totalCount
      crewMemberRegistration {
        roomNumber
        pinNumber
        dateTime
        firstName
        lastName
        propertyName
        actionType
      }
    }
  }
`;
export const QUERY_CREW_MEMBER_REGISTRATION_BY_REFERENCE_NUMBER = gql`
  query QUERY_CREW_MEMBER_REGISTRATION_BY_REFERENCE_NUMBERS(
    $referenceNumbers: [String!]
    $organizationId: Int
    $token: String
    $top: Int
    $skip: Int
  ) {
    getCrewMemberRegistration(
      referenceNumbers: $referenceNumbers
      organizationId: $organizationId
      token: $token
      top: $top
      skip: $skip
    ) {
      message
      submission {
        firstName
        lastName
        emailAddress
        referenceNumber
      }
    }
  }
`;

export const MUTATION_GENERATE_TOKEN_CREW_REGISTRATION = gql`
  mutation MUTATION_GENERATE_TOKEN_CREW_REGISTRATION($token: String) {
    mutationGenerateTokenForCrewRegistration(token: $token) {
      message
      token
    }
  }
`;
