import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCSVDownloader } from 'react-papaparse';
import { StyledButton } from './DownloadCsvButton.styles';

export const DownloadCsvButton = (props) => {
  const { CSVDownloader } = useCSVDownloader();
  const { csvData, fileTitle, columns, startDate, endDate, tableData } = props;
  const downloadableCSVFileName = `${fileTitle}_${startDate}_${endDate}`;

  const formatCSVData = ({ data }) => {
    const headerKeys = columns.map(({ key }) => key);
    const ordered = headerKeys.map((key) => {
      if (data[key]) {
        return data[key];
      }
      return '';
    });
    return ordered;
  };

  const formatDataForCrewRegistrationSignInSheet = () => {
    // Map headers to camelCase keys
    const downloadableCrewList = csvData.map((data) => formatCSVData({ data }));
    const headerKeys = columns.map(({ value }) => value);

    return [headerKeys, ...downloadableCrewList];
  };

  const renderCSVDownloadButton = () => {
    const data = formatDataForCrewRegistrationSignInSheet({
      header: [...columns],
      data: csvData
    });
    return (
      <CSVDownloader data={data} filename={downloadableCSVFileName} bom>
        <StyledButton disabled={!csvData?.length || !tableData?.length} name="downloadCSV" primary="true">
          Download Sheet
        </StyledButton>
      </CSVDownloader>
    );
  };

  return renderCSVDownloadButton();
};

DownloadCsvButton.propTypes = {
  csvData: PropTypes.arrayOf([{}]),
  tableData: PropTypes.arrayOf([{}]),
  fileTitle: PropTypes.string,
  columns: PropTypes.arrayOf([{ key: PropTypes.string, value: PropTypes.string }])
};

DownloadCsvButton.defaultProps = {
  csvData: [],
  tableData: [],
  fileTitle: 'data_export',
  columns: []
};
